.arttile {
    border: 0;
    position: relative;
    transition: transform 0.3s ease, filter 0.3s ease;
    margin: 4px;
    margin-bottom: 8px;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
    pointer-events: none;
    z-index: 1;
}

.arttile:hover {
    transform: scale(1.005);
    z-index: 1;
    transition: transform 0.4s ease, filter 0.5s ease;
    cursor: pointer;
}

.artimage:hover {
    filter: brightness(0.3);
    transition: filter 0.6s ease;
}

.image-text {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    color: white;
    text-align: center;
    padding: 10px;
    opacity: 0;
    transition: opacity 0.4s ease;
    z-index: 5;
    text-align: left;
    pointer-events: none;
}

.image-text p {
    font-size: 1rem !important;
    line-height: 100% !important;
}
  
.arttile:hover .image-text {
    opacity: 1;
}