.totop {
    position: fixed;
    bottom: 0.6rem;
    right: 0.6rem;
    z-index: 299;
    background-color: var(--top-button);
    border-color: var(--top-button);
    color: var(--white);
    font-size: 1.1rem;
    height: 2.4rem;
    width: 2.4rem;
    padding: .35rem 0rem;
}

.totop:hover {
    background-color: var(--orange) !important;
    border-color: var(--orange) !important;
}

.totop:active {
    background-color: var(--top-button);
    border-color: var(--top-button);
}

.totop:focus {
    background-color: var(--top-button);
    border-color: var(--top-button);
}