.off-canvas .home {
    padding: 0;
    width: 100%;
}

.hero-lg h1{
    font-size: 3rem;
}

.hero-lg p{
    font-size: 1.3rem;
    line-height: 110%;
}