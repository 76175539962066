#intro {
    min-height: max(calc(100vh - 2.8rem), 600px);
    padding-bottom: 0;
}

#intro .hero-body.container {
    display: flex;
    padding-left: 4vw;
    padding-bottom: 0rem;
    padding-right: 0;
    min-height: max(calc(100vh - 6.8rem), 600px - 4rem);
}

#intro .hero-body .columns {
    min-height: max(calc(100vh - 6.8rem), 600px - 4rem);
    width: 100%;
    margin-left: 0;
}

#intro .hero-body .columns .column.introcontent {
    padding-top: max(calc(40vh - 4.5rem), 240px - 3.5rem);
    z-index: 2;
}

.introcontent h1 {
    font-size: 4rem;
}

.introcontent p {
    font-size: 2rem;
    line-height: 105%;
}

#intro .hero-body .columns .column{
    height: max(calc(100vh - 6.8rem), 600px - 4rem);
    padding-right: 0;
}

.introimgcontainer {
    position: relative;
    overflow: visible;
}

#introimg {
    height: 100%;
    position: absolute;
    right: 0%;
}