.navbar {
    box-shadow: 0 .25rem 1rem rgba(48,55,66,.15);

    position: sticky;
    top: 0;
    z-index: 99;
    height: 2.8rem;
    margin-bottom: 0;
    padding: 0.5rem 1rem 0.5rem 1.6rem;
}

.navbar-section .btn.btn-link {
    padding: 0.24rem 0.6rem;
}

.navbar-section a{
    color: black;
    text-decoration-line: none;
    box-shadow: none;
}