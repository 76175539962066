:root {
    --yellow: #fbe8a6;
    --orange: #f4976c;
    --navy: #35478d;
    --greyblue: #b4dfe5;
    --grey: #c9dfe0;
    --white: white;
    --black: black;

    --nav-button: var(--navy);
    --nav: var(--white);
    --nav-link: var(--navy);
    --hover: var(--orange);
    --intro: var(--white);
    --resume-button: var(--navy);
    --top-button: var(--navy);
    --timeline-line: var(--grey);
    --timeline-icon: var(--navy);
    --card: var(--greyblue);
    --background: var(--white);
    --footer: var(--greyblue);
}

/* [data-theme='dark'] {
    --background: black;
    --text-primary: white;
    --text-secondary: grey;
    --accent: darkred;
} */

body {
    color: var(--black);
}

.navbar {
    background-color: var(--nav);
}

.navbar-section .btn.btn-link {
    color: var(--nav-link);
}

.navbar-section .btn.btn-link:hover {
    color: var(--hover);
    border-bottom: 0.1rem solid var(--hover);
}

.off-canvas-toggle{
    background-color: var(--nav-button);
    border-color: var(--nav-button);
    color: #fff;
}

.off-canvas-toggle:focus,
.off-canvas-toggle:hover {
    background-color: var(--hover);
    border-color: var(--hover);
    color: #fff;
    transition: 0.2s;
}

.off-canvas-toggle:active,
.off-canvas-toggle.active {
    background-color: var(--nav-button);
    border-color: var(--nav-button);
    color: #fff;
}

#sidebar-id .btn.btn-link {
    color: var(--nav-link);
}

#sidebar-id .btn.btn-link:hover {
    color: var(--hover);
    border-bottom: 0.1rem solid var(--hover);
}


#intro {
    background: var(--intro);
    color: #000;
}


#resumebtn {
    background-color: var(--resume-button);
    border-color: var(--resume-button);
    color: #fff;
}

#resumebtn:hover {
    background-color: var(--hover) !important;
    border-color: var(--hover) !important;
}

#resumebtn:focus,
#resumebtn:active,
#resumebtn.active {
    background-color: var(--resume-button);
    border-color: var(--resume-button);
}

.hero.hero-lg {
    padding-top: calc(-9rem + 30vh + 5vw);
    padding-left: 10vw;
    padding-right: 10vw;
    margin-top: 20vh;
}


#about {
    background: var(--background);
    color: #000;
}


.timeline .timeline-item::before {
    background: var(--timeline-line);
}

.timeline .timeline-item .timeline-icon.icon-lg {
    background: var(--timeline-icon);
}

.timeline .timeline-item .timeline-icon::before {
    border-color: var(--timeline-icon);
}


.protile {
    background-color: var(--card);
    color: #000;
}


#footer {
    background: var(--footer);
}