#artdetail {
    margin-top: 0;
    padding-top: 0;
}

.artMainImage {
    margin-bottom: 4rem;
    max-height: calc(100vh - 3.6rem);
}

.artImage {
    margin-bottom: 1rem;
    max-height: calc(100vh - 3.6rem);
}

.artVideo {
    margin-bottom: 1rem;
    max-width: 100%;
}

.imageBox {
    justify-content: center;
    display: flex;
}

.artcontent {
    margin-bottom: 4rem;
}
